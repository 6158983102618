import { NavLink } from "react-router-dom";

function Nav() {
  return (
    <div className="App">
      <div
        className="navbar navbar-expand-sm navbar-light bg-warning container-fluid position-relative"
        style={{ left: "0", height: "40px" }}
      >
        <div className="container">
          <ul className="navbar-nav">
            <li className="nav-item">
              <span className="tobbaritem">
                |&nbsp;&nbsp;&nbsp; <i className="fa fa-phone smallicon"></i>{" "}
                &nbsp;+(0271)746 2097 &nbsp;&nbsp;&nbsp;
              </span>
            </li>
            <li className="nav-item">
              <span className="tobbaritem">
                |&nbsp;&nbsp;&nbsp; <i className="far fa-envelope"></i>&nbsp;
                klinikummat@klinikummat.com
              </span>
            </li>
          </ul>
          <a
            href="/login"
            style={{ textDecoration: "none" }}
            className="ms-auto pe-2"
          >
            <span className="navbar-nav py-1 text-white">admin</span>
          </a>
        </div>
      </div>

      <div
        className="navbar navbar-expand-sm navbar-light ps-5 pe-3 border-bottom position-relative left-0"
        style={{ left: "0" }}
      >
        <div className="container">
          <span className="navbar-brand">
            <img src="gambar/logo.png" width="70%" alt="" />
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#toogleMobilemenu"
            aria-controls="toogleMobilemenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="toogleMobilemenu">
            <ul className="navbar-nav ms-auto text-center mb-2 mb-lg-0 ">
              <li className="nav-item px-2">
                <NavLink
                  to="/"
                  className="nav-link btn-group-navclient"
                  exact
                  activeClassName="active"
                  style={{ textDecoration: "none" }}
                >
                  Home
                </NavLink>
              </li>

              <li className="nav-item px-2">
                <NavLink
                  to="/tentangkami"
                  exact
                  activeClassName="active"
                  className="nav-link btn-group-navclient"
                  style={{ textDecoration: "none" }}
                >
                  Tentang Kami
                </NavLink>
              </li>

              <li className="nav-item px-2">
                <NavLink
                  to="/layanan"
                  exact
                  className="nav-link btn-group-navclient"
                  style={{ textDecoration: "none" }}
                >
                  Layanan
                </NavLink>
              </li>

              <li className="nav-item px-2">
                <NavLink
                  to="/berita"
                  exact
                  className="nav-link btn-group-navclient"
                  style={{ textDecoration: "none" }}
                >
                  Berita
                </NavLink>
              </li>

              <li className="nav-item ps-3 pe-0 me-0">
                <NavLink
                  to="/404"
                  exact
                  className="nav-link btn-group-navclient btn btn-primary btn-greenprimary  px-4 text-white"
                  style={{ textDecoration: "none" }}
                >
                  Member
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <ul></ul>
      </div>
    </div>
  );
}

export default Nav;
