function Footer() {
    return (
      <div className="App">        
        <svg style={{marginTop:"-150px"}} className="glowfooter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#39B54A" fillOpacity="1" d="M0,256L80,266.7C160,277,320,299,480,309.3C640,320,800,320,960,298.7C1120,277,1280,235,1360,213.3L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path></svg>
        <div className="fluid-container color-white bg-primarygreen-main py-5 px-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-5">
                <div className="row">
                  <div className="col-2 ps-0">
                    <img src="/gambar/logowhitecreep.gif" className="" alt="150px"/>
                  </div>
                  <div className="col-9 mb-4">
                    
                     Klinik UMMAT memberikan pelayanan gratis kepada masyarakat dhuafa yang telah terdaftar sebagai anggota (member) Klinik UMMAT.
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <p><b>Kontak Kami</b></p>
                Jl. Songgo Bumi No.11-9, Dusun I, Manang, Kab. Sukoharjo, <br/>
                klinikummat@klinikummat.com <br/>
                +(0271)746 2097 <br/>
                +62 813-3210-6647

              </div>

              <div className="col-sm-3">
                <p><b>Social Media</b></p>
                <span className="boxicon bg-facebook"><i className="fa fa-facebook fontsize"></i></span>
                <span className="boxicon bg-ig"><i className="fa fa-instagram fontsize"></i></span>
                <span className="boxicon bg-whatsapp"><i className="fa fa-whatsapp fontsize"></i></span>
                <span className="boxicon bg-email"><i className="fa fa-envelope fontsize"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid btn-yellowprimary">
          <div className="container py-3">
          @Copyright Klinikummat 2021 | Desgin by Adansubtyatama
          </div>
        </div>
      </div>
    );
  }

  export default Footer;