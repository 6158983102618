import { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import { link } from "../../axios/link";
import { useHistory } from "react-router";

function TambahLayanan() {
  const [addData, setVal] = useState("");
  const { register, handleSubmit, reset, errors } = useForm();
  const history = useHistory();
  function tambahLayanan(data) {
    const formData = new FormData();
    formData.append("judullayanan", data.judullayanan);
    formData.append("gambar", data.gambar[0]);
    formData.append("isilayanan", addData);
    formData.append("user_id", sessionStorage.getItem("id_user"));

    link.post("/layanan/create", formData).then((res) => alert(res.data.pesan));
    setVal("");
    reset();
    history.push("/admin/layanan");
  }
  const handleChange = (e, editor) => {
    const datas = editor.getData();
    setVal(datas);
  };
  return (
    <div className="App">
      <div className="main-wrapper">
        <div className="navbar-bg" />
        {/* Main Content */}
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>
                <i
                  className="fa fa-plus-square me-4"
                  style={{ fontSize: "0.9em" }}
                ></i>
                Tambah Layanan Baru
              </h1>
            </div>
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Tambah Data Layanan !</h4>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(tambahLayanan)}>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>Judul Layanan</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("judullayanan", { required: true })}
                              placeholder="Masukan judul layanan..."
                              name="judullayanan"
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>File</label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("gambar", { required: true })}
                              placeholder="Masukan gambar..."
                              name="gambar"
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>Isi Layanan</label>
                          </div>
                          <CKEditor
                            editor={ClassicEditor}
                            data={addData}
                            onChange={handleChange}
                          />
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default TambahLayanan;
