import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { link } from "../../axios/link";
import { useHistory, Redirect } from "react-router-dom";
function Editmanajemenuser(props) {
  const [isi, setisi] = useState([]);
  const [Loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, errors, setValue } = useForm();
  const history = useHistory();
  const userId = props.match.params.userId;
  async function fetchData() {
    const req = await link.get(`/user/${userId}`);
    setisi(req.data);
    setValue("name", req.data.name);
    setValue("username", req.data.username);
    setValue("email", req.data.email);
    setValue("level", req.data.level);
    if (req.data.status == "1") {
      setValue("status", true);
    } else {
      setValue("status", false);
    }

    setLoading(true);
  }
  async function updateData(data) {
    console.log(data);
    var stats;
    data.status ? (stats = "1") : (stats = "0");
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append("name", data.name);
    formData.append("username", data.username);
    formData.append("email", data.email);
    formData.append("image", data.image[0]);
    formData.append("level", data.level);
    formData.append("status", stats);
    formData.append("password", data.password);

    try {
      link
        .post(`/user/${userId}/edit`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => alert(res.data.pesan));
      reset();

      history.push("/admin/manajemenuser");
    } catch (e) {
      alert(e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  if (sessionStorage.getItem("level") != "1") {
    sessionStorage.setItem("pesan", "Anda Harus Login Dahulu !");
    return <Redirect to="/admin" />;
  }
  return (
    <div>
      <div className="main-wrapper">
        <div className="navbar-bg" />
        {/* Main Content */}
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>
                <i
                  className="fa fa-plus-square me-4"
                  style={{ fontSize: "0.9em" }}
                ></i>
                Update User
              </h1>
            </div>
            {Loading ? (
              <div className="visually-hidden">{isi.id}</div>
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Update Data User !</h4>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(updateData)}>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>Nama Lengkap</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("name", { required: true })}
                              placeholder="Masukan nama lengkap pengguna..."
                              name="name"
                              required
                            />
                            <div className="invalid-feedback">
                              Masukan Nama Lengkap dengan benar !
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>Username ( Nama Pengguna )</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("username", { required: true })}
                              placeholder="Masukan username pengguna..."
                              name="username"
                              required
                            />
                            <div className="invalid-feedback">
                              Masukan Username dengan benar !
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>Email Pengguna</label>
                            <input
                              type="email"
                              className="form-control"
                              {...register("email", { required: true })}
                              placeholder="Masukan email pengguna..."
                              name="email"
                              required
                            />
                            <div className="invalid-feedback">
                              Masukan Email dengan benar !
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>Level</label>
                            <br />
                            <select
                              {...register("level", { required: true })}
                              className="form-control custom-select col-12"
                            >
                              <option value="">Pilih Level...</option>
                              <option value="1">Administrator Utama</option>
                              <option value="2">
                                Administrator Penyunting{" "}
                              </option>
                            </select>
                            <div className="invalid-feedback">
                              Masukan Level dengan benar !
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>
                              Password ( Kosongi jika tidak ingin ganti password
                              )
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              {...register("password", { required: false })}
                              placeholder="Masukan password pengguna jika akan diganti..."
                              name="password"
                            />
                            <div className="invalid-feedback">
                              Masukan Password dengan benar !
                            </div>
                          </div>
                        </div>

                        <div className="mb-3">
                          <div className="form-group">
                            <div className="control-label">Status</div>
                            <label className="custom-switch mt-2">
                              <input
                                type="checkbox"
                                name="custom-switch-checkbox"
                                {...register("status", { required: false })}
                                className="custom-switch-input"
                                value="1"
                              />
                              <span className="custom-switch-indicator" />
                              <span className="custom-switch-description form-text">
                                Hidup = Akun Aktif
                              </span>
                            </label>
                          </div>
                        </div>
                        <div>
                          <div className="form-group mb-0">
                            <label>foto profil</label>
                          </div>
                          <img
                            src={`${isi.image}`}
                            width="30%"
                            alt="gambar"
                            className="img-thumbnail"
                          />
                        </div>
                        <div className="mb-3 mt-2">
                          <div className="form-group">
                            <input
                              type="file"
                              className="form-control"
                              {...register("image", { required: false })}
                              placeholder="Masukan gambar..."
                              name="image"
                            />
                          </div>
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
export default Editmanajemenuser;
