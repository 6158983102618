import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { link } from "../../axios/link";
function InformasiUser() {
  const [isi, setisi] = useState([]);
  const [Loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, errors, setValue } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    errors: errors2,
    setValue: setValue2,
  } = useForm();
  async function ubahDatauser(data) {
    console.log(data.image[0]);
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append("name", data.name);
    formData.append("username", data.username);
    formData.append("image", data.image[0]);
    formData.append("email", data.email);
    formData.append("password", data.password);
    link
      .post(`/user/${sessionStorage.getItem("id_user")}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => alert(res.data.pesan));
    fetchData();
  }
  async function gantiPassword(data) {
    const formData2 = new FormData();
    formData2.append("_method", "put");
    formData2.append("username", data.username);
    formData2.append("oldpassword", data.oldpassword);
    formData2.append("newpassword", data.newpassword);

    link
      .post(
        `/user/changepassword/${sessionStorage.getItem("id_user")}`,
        formData2,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => alert(res.data.pesan));
    fetchData();
  }
  async function fetchData() {
    const req = await link.get(`user/${sessionStorage.getItem("id_user")}`);
    setisi(req.data);
    setValue("name", req.data.name);
    setValue("username", req.data.username);
    setValue("email", req.data.email);
    setLoading(true);
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="App">
      <div className="main-wrapper">
        <div className="navbar-bg" />
        {/* Main Content */}
        <div className="main-content">
          {Loading ? (
            isi.id
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <section className="section">
            <div className="section-header">
              <h1>
                <i
                  className="fa fa-user me-4"
                  style={{ fontSize: "0.9em" }}
                ></i>
                Informasi User
              </h1>
            </div>
            <div className="section-body">
              <h2 className="section-title">Hi, {isi.username}</h2>
              <p className="section-lead">
                Ubah informasi dasar akun anda pada halaman ini.
              </p>
              <div className="row mt-sm-4">
                <div className="col-12 col-md-12 col-lg-5">
                  <div className="card profile-widget">
                    <div className="profile-widget-header">
                      <img
                        alt="image"
                        src={`${isi.image}`}
                        className="rounded-circle profile-widget-picture"
                      />
                      <div className="profile-widget-items">
                        <div className="profile-widget-item">
                          <div className="profile-widget-item-label">
                            Sunting Berita
                          </div>
                          <div className="profile-widget-item-value">187</div>
                        </div>
                        <div className="profile-widget-item">
                          <div className="profile-widget-item-label">
                            Sunting Layanan
                          </div>
                          <div className="profile-widget-item-value">187</div>
                        </div>
                      </div>
                    </div>
                    <div className="profile-widget-description">
                      <div className="profile-widget-name">
                        {isi.name}
                        <div className="text-muted d-inline font-weight-normal">
                          <div className="slash" />{" "}
                          {isi.level == 1
                            ? "Administrator Utama"
                            : "Admin Penyunting"}
                        </div>
                      </div>
                      {isi.name} adalah Manusia yang berani memperjuangkan{" "}
                      <b>Islam</b> sampai ia menang atau mati. Orang-orang yang
                      berjuang itu pun tidak memperdulikan apakah ia bakal
                      mendapat penghargaan atau tidak dari institusi manapun,
                      yang mereka harapkan adalah keridhaan dari{" "}
                      <b>Allah Swt</b>.
                    </div>
                    <div className="card-footer">
                      <span className="text-muted form-text">
                        فَاِذَا فَرَغۡتَ فَانۡصَبۡۙ - Qs. Al-Insyirah:7
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-7">
                  <div className="card">
                    <form
                      method="post"
                      className="needs-validation"
                      onSubmit={handleSubmit(ubahDatauser)}
                      noValidate
                    >
                      <div className="card-header">
                        <h4>Ubah Data User</h4>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="form-group col-12">
                            <label>Nama Lengkap</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("name", { required: true })}
                              required
                            />
                            <div className="invalid-feedback">
                              Masukan Nama Panjang dengan benar
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-12">
                            <label>Username / Nama Pengguna</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("username", { required: true })}
                              required
                            />
                            <div className="invalid-feedback">
                              Masukan Username dengan benar
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-12">
                            <label>Email</label>
                            <input
                              type="email"
                              className="form-control"
                              {...register("email", { required: true })}
                              required
                            />
                            <div className="invalid-feedback">
                              Masukan Email dengan benar
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-12">
                            <label>Foto Profil</label>
                            <input
                              type="file"
                              className="form-control"
                              {...register("image", { required: false })}
                              placeholder="Masukan gambar..."
                              name="image"
                            />
                            <div className="invalid-feedback">
                              Masukan Foto dengan benar
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-12">
                            <label>Password Untuk Konfirmasi</label>
                            <input
                              type="password"
                              className="form-control"
                              {...register("password", { required: true })}
                              required
                            />
                            <div className="invalid-feedback">
                              Masukan password dengan benar
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-0 col-12">
                            <div className="text-muted form-text">
                              Anda akan mengubah data pribadi anda, pastikan
                              memasukanya dengan benar. Silahkan reload atau
                              Login Ulang jika data belum berubah.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer text-right">
                        <button className="btn btn-primary">
                          Simpan Perubahan
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="card">
                    <form
                      method="post"
                      onSubmit={handleSubmit2(gantiPassword)}
                      className="needs-validation"
                      noValidate
                    >
                      <div className="card-header">
                        <h4>Ganti Password</h4>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="form-group col-12">
                            <label>Username</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Username..."
                              {...register2("username", { required: true })}
                              required
                            />
                            <div className="invalid-feedback">
                              Masukan Username dengan benar
                            </div>
                          </div>

                          <div className="form-group col-12">
                            <label>Password Lama</label>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password Lama..."
                              {...register2("oldpassword", { required: true })}
                              required
                            />
                            <div className="invalid-feedback">
                              Masukan Password Lama dengan benar
                            </div>
                          </div>
                          <div className="form-group col-12">
                            <label>Password Baru</label>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password Baru..."
                              {...register2("newpassword", { required: true })}
                              required
                            />
                            <div className="invalid-feedback">
                              Masukan Password Baru dengan benar
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-0 col-12">
                            <div className="text-muted form-text">
                              Pastikan anda mencatat password yang baru sebelum
                              mengganti password.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        <button className="btn btn-primary">
                          Ganti Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
export default InformasiUser;
