import { NavLink, Link } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";

function Sidenav() {
  const url = "/admin";
  return (
    <div className="main-sidebar">
      <aside id="sidebar-wrapper">
        <div className="sidebar-brand">
          <Link to="/admin">KLINIK UMMAT ( ADMIN )</Link>
        </div>
        <div className="sidebar-brand sidebar-brand-sm">
          {/* exact activeClassName="active" */}
          <Link to="/admin">
            <img src="gambar/logoonly-04.png" className="w-50" alt="logo" />
          </Link>
        </div>
        <ul className="sidebar-menu">
          {/* Dashboard Section */}
          <li className="menu-header">Dashboard</li>
          <li>
            <NavLink
              to="/admin"
              exact
              activeClassName="active"
              className="nav-link"
            >
              <i className="fas fa-fire"></i> <span>Dashboard</span>
            </NavLink>
          </li>
          {/* User Section */}
          <li className="menu-header">Manajemen User</li>
          <li>
            <NavLink to="/admin/informasiuser" className="nav-link" href="">
              <i className="fas fa-user"></i> <span>Informasi Pengguna</span>
            </NavLink>
          </li>
          {sessionStorage.getItem("level") == "1" ? (
            <li>
              <NavLink to="/admin/manajemenuser" className="nav-link" href="">
                <i className="fas fa-users"></i> <span>Manajemen User</span>
              </NavLink>
            </li>
          ) : (
            ""
          )}

          {/* Layanan Section */}
          <li className="menu-header">LAYANAN DAN BERITA</li>

          <li className="nav-item dropdown">
            <a href="#" className="nav-link has-dropdown">
              <i className="fas fa-hand-holding-medical" />
              <span>Layanan</span>
            </a>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to={`${url}/layanan`}
                  exact
                  activeClassName="active"
                  className="nav-link"
                >
                  Data List Layanan
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${url}/tambahlayanan`}
                  exact
                  activeClassName="active"
                  className="nav-link"
                >
                  Tambah Layanan Baru
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="nav-item dropdown">
            <a href="#" className="nav-link has-dropdown">
              <i className="fas fa-newspaper" />
              <span>Berita</span>
            </a>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  to={`${url}/berita`}
                  exact
                  activeClassName="active"
                  className="nav-link"
                >
                  Data List Berita
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`${url}/tambahberita`}
                  exact
                  activeClassName="active"
                  className="nav-link"
                >
                  Tambah Berita Baru
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu-header">MEMBER</li>
          <div className="mb-4 p-3 hide-sidebar-mini">
            <a
              href="#"
              className="btn btn-primary btn-lg btn-block btn-icon-split"
            >
              <i className="fas fa-id-card" /> Member ( Coming Soon )
            </a>
          </div>
        </ul>
      </aside>
    </div>
  );
}
export default Sidenav;
