import React from "react";
import { useEffect, useState } from "react";
import { link } from "../axios/link";
import DataTable from "datatables.net";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { render } from "@testing-library/react";

function Datatabel(props) {
  const [isi, setIsi] = useState([]);

  const [Loading, setLoading] = useState(false);
  const $ = require("jquery");
  const linknya = props.linkdata;
  const history = useHistory();

  async function fetchdata() {
    const request = await link.get(`/${linknya}`);
    setIsi(request.data);
    setLoading(true);
    $("#table-2").DataTable();
  }

  function hapus(id) {
    if (window.confirm("Ingin menghapus data ini ?")) {
      setLoading(false);
      link.delete(`${linknya}/${id}`).then((res) => alert(res.data.pesan));
      history.go("/admin/berita");
    }
  }
  function retusn() {
    return isi.map((val, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        {props.kolomapi.map((column) => (
          <td key={column}>{val[column]}</td>
        ))}
        <td className="center">
          <button
            onClick={() => hapus(val.id)}
            className="btn btn-icon btn-sm btn-danger"
          >
            <i className="fa fa-trash"></i>
          </button>
          <Link
            to={`/admin/edit${linknya}/${val.id}`}
            className="btn btn-icon btn-sm btn-success"
          >
            <i className="fa fa-edit"></i>
          </Link>
          <Link
            to={`/admin/detail${linknya}/${val.id}`}
            className="btn btn-icon btn-sm btn-primary"
          >
            <i className="fa fa-file"></i>
          </Link>
        </td>
      </tr>
    ));
  }

  useEffect(() => {
    fetchdata();
  }, [isi]);

  return (
    <div className="App">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4>{props.titletabel}</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped" id="table-2">
                  <thead>
                    <tr key="-2">
                      {props.kolom.map((val, index) => (
                        <th key={index}>{val}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* loading spinner */}
                    {Loading ? (
                      isi.id
                    ) : (
                      <tr key="-1" className="d-flex justify-content-center">
                        <td>
                          <span className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </span>
                        </td>
                      </tr>
                    )}
                    {retusn()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Datatabel;
