import Datatabel from "../Datatabel";
import { NavLink } from "react-router-dom";

function LayananAdmin() {
  return (
    <div>
      <div className="main-wrapper">
        <div className="navbar-bg" />
        {/* Main Content */}
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>
                <i
                  className="fa fa-newspaper me-4"
                  style={{ fontSize: "0.9em" }}
                ></i>
                Layanan
              </h1>
            </div>
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <NavLink
                    to="/admin/tambahlayanan"
                    href="#"
                    className="btn btn-icon icon-left btn-primary"
                  >
                    <i className="far fa-plus-square"></i> Tambah Layanan Baru{" "}
                  </NavLink>
                </div>
              </div>
              <div>
                <Datatabel
                  kolom={[
                    "No",
                    "Judul Layanan",
                    "Gambar",
                    "Penyunting Layanan",
                    "Aksi",
                  ]}
                  kolomapi={["judullayanan", "gambar", "name"]}
                  titletabel="Data Layanan"
                  linkdata="layanan"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default LayananAdmin;
