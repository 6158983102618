import { Link } from "react-router-dom";
import Navside from "./Navside";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { link } from "../axios/link";
import ReactHtmlParser from "react-html-parser";

function Layanan() {
  const [isi, setIsi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setIsiPerPage] = useState(6);

  useEffect(() => {
    async function fetchData() {
      try {
        const request = await link.get("/layanan").then((res) => {
          setIsi(res.data);
        });
        setLoading(true);
      } catch (e) {
        console.log(e);
      }
    }

    fetchData();
    Aos.init({ duration: 1500 });
  }, []);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = isi.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumbers) => setCurrentPage(pageNumbers);
  const pageNumbers = [];
  for (let index = 1; index <= Math.ceil(isi.length / postPerPage); index++) {
    pageNumbers.push(index);
  }

  return (
    <div className="App">
      {/* konten layanan */}
      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-9">
            <h1 data-aos="fade-right" className="teksbanner mb-4 mt-5">
              Layanan Terkini
            </h1>
            <hr className="mb-3" />
            <div className="row px-3">
              {/* spinner */}
              {loading ? (
                isi.id
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}

              {/* Konten Utama */}
              {currentPosts.map((val, index) => (
                <div className="col-md-4 mb-5" key={index}>
                  <div className="card">
                    <img
                      src={val.gambar}
                      className="card-img-top layananterkini-thubmnail"
                      alt="gambar"
                    />
                    <div className="card-body">
                      <h5 className="card-title">{val.judullayanan}</h5>
                      <p className="card-text">
                        {ReactHtmlParser(val.isilayanan.substring(0, 200))} ...
                      </p>
                      <Link to={`/detaillayanan/${val.id}`}>
                        <span className="btn btn-warning btn-yellowprimary">
                          Cek Detail Layanan
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <nav className="mt-5" aria-label="Page navigation">
              <ul className="pagination">
                {pageNumbers.map((number) => (
                  <li key={number} className="page-item">
                    <a
                      onClick={() => paginate(number)}
                      className="page-link"
                      href="#"
                    >
                      {number}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className="col-sm-3 pt-3">
            <Navside title="Berita Terkini" linkdata="limitberita" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layanan;
