import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { link } from "../../axios/link";
import Datatabel from "../Datatabel";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
function ManajemenUser() {
  const [isi, setisi] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { register, handleSubmit, reset, errors, setValue } = useForm();
  const history = useHistory();
  async function tambahUserbaru(data) {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("username", data.username);
    formData.append("email", data.email);
    formData.append("image", data.image[0]);
    formData.append("level", data.level);
    formData.append("password", data.password);

    try {
      link.post("/user/create", formData).then((res) => alert(res.data.pesan));
      reset();
      hideKan();
      history.go("admin/manajemenuser");
    } catch (e) {
      alert(e);
    }
  }
  function showKan() {
    setShow(true);
  }
  function hideKan() {
    setShow(false);
  }

  useEffect(() => {}, []);
  if (sessionStorage.getItem("level") != "1") {
    sessionStorage.setItem("pesan", "Anda Harus Login Dahulu !");
    return <Redirect to="/admin" />;
  }
  return (
    <div className="App">
      <div className="main-wrapper">
        <div className="navbar-bg" />
        {/* Main Content */}
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>
                <i
                  className="fa fa-user me-4"
                  style={{ fontSize: "0.9em" }}
                ></i>
                Manajemen User
              </h1>
            </div>
            <div className="section-body">
              <h2 className="section-title">Halo Administrator Utama</h2>
              <p className="section-lead">
                Ubah informasi dasar akun anda pada halaman ini.
              </p>

              <div className="row">
                <div className="col-12">
                  {show ? (
                    ""
                  ) : (
                    <button
                      className="btn btn-icon icon-left btn-primary"
                      onClick={showKan}
                    >
                      <i className="far fa-plus-square"></i> Tambah User Baru
                    </button>
                  )}
                </div>
              </div>
              {show ? (
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <form action="" onSubmit={handleSubmit(tambahUserbaru)}>
                        <div className="card-header">
                          <h4>Tambah User Baru</h4>
                          <div className="card-header-action">
                            <button
                              data-collapse="#mycard-collapse"
                              className="btn btn-icon btn-danger"
                              onClick={hideKan}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <label>Nama Lengkap</label>
                                <input
                                  className="form-control col-12"
                                  type="text"
                                  {...register("name", { required: true })}
                                  placeholder="Masukan Nama Lengkap..."
                                />
                                <div className="invalid-feedback">
                                  Masukan Nama Lengkap dengan benar !
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <label>Username</label>
                                <input
                                  className="form-control col-12"
                                  type="text"
                                  {...register("username", { required: true })}
                                  placeholder="Masukan Username..."
                                />
                                <div className="invalid-feedback">
                                  Masukan Username dengan benar !
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <label>Email Pengguna</label>
                                <input
                                  className="form-control col-12"
                                  type="text"
                                  {...register("email", { required: true })}
                                  placeholder="Masukan Email Pengguna..."
                                />
                                <div className="invalid-feedback">
                                  Masukan Email Pengguna dengan benar !
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <label>Level</label>
                                <br />
                                <select
                                  name=""
                                  id=""
                                  {...register("level", { required: true })}
                                  className="form-control custom-select col-12"
                                >
                                  <option value="">Pilih Level...</option>
                                  <option value="1">Administrator Utama</option>
                                  <option value="2">
                                    Administrator Penyunting{" "}
                                  </option>
                                </select>
                                <div className="invalid-feedback">
                                  Masukan Level dengan benar !
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <label>Foto Profil</label>
                                <input
                                  className="form-control col-12"
                                  type="File"
                                  {...register("image", { required: false })}
                                  placeholder="Masukan Foto Profil..."
                                />
                                <div className="invalid-feedback">
                                  Masukan Foto Profil dengan benar !
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <label>Password</label>
                                <input
                                  className="form-control col-12"
                                  type="password"
                                  {...register("password", { required: true })}
                                  placeholder="Masukan Password Pengguna..."
                                />
                                <div className="invalid-feedback">
                                  Masukan dengan benar !
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer text-right">
                          <button className="btn btn-primary">
                            Tambah Pengguna
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                " "
              )}
              <div>
                <Datatabel
                  kolom={[
                    "No",
                    "Nama Lengkap",
                    "Username",
                    "Email Pengguna",
                    "Level",
                    "Status",
                    "Aksi",
                  ]}
                  kolomapi={["name", "username", "email", "level", "status"]}
                  titletabel="Data Penyunting / Pengguna"
                  linkdata="user"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
export default ManajemenUser;
