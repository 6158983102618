import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import { link } from "../../axios/link";
import { useHistory } from "react-router";

function Updatelayanan(props) {
  const [addData, setVal] = useState();
  const [isi, setIsi] = useState([]);
  const [Loading, setLoading] = useState();
  const { register, handleSubmit, reset, errors, setValue } = useForm();
  const history = useHistory(false);
  async function updateLayanan(data) {
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append("judullayanan", data.judullayanan);
    formData.append("gambar", data.gambar[0]);
    formData.append("isilayanan", addData);
    formData.append("user_id", sessionStorage.getItem("id_user"));
    link
      .post(`/layanan/${props.match.params.layananId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => alert(res.data.pesan));
    setVal("");
    reset();
    history.push("/admin/layanan");
  }

  useEffect(() => {
    async function fetchData() {
      const req = await link.get(`/layanan/${props.match.params.layananId}`);
      setIsi(req.data);
      setVal(req.data.isilayanan);
      setValue("judullayanan", req.data.judullayanan);
      setValue("tanggal", req.data.tanggal);
      setLoading(true);
    }
    fetchData();
  }, []);
  const handleChange = (e, editor) => {
    const datas = editor.getData();
    setVal(datas);
  };

  return (
    <div>
      <div className="main-wrapper">
        <div className="navbar-bg" />
        {/* Main Content */}
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>
                <i
                  className="fa fa-plus-square me-4"
                  style={{ fontSize: "0.9em" }}
                ></i>
                Update layanan
              </h1>
            </div>
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Update Data layanan !</h4>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(updateLayanan)}>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>Judul layanan</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("judullayanan", { required: true })}
                              placeholder="Masukan judul layanan..."
                              name="judullayanan"
                              required
                            />
                          </div>
                        </div>
                        <div>
                          <div className="form-group mb-0">
                            <label>Gambar</label>
                          </div>
                          <img
                            src={`${isi.gambar}`}
                            width="60%"
                            alt="gambar"
                            className="img-thumbnail"
                          />
                        </div>
                        <div className="mb-3 mt-2">
                          <div className="form-group">
                            <input
                              type="file"
                              className="form-control"
                              {...register("gambar", { required: false })}
                              placeholder="Masukan gambar..."
                              name="gambar"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>Isi layanan</label>
                          </div>
                          <CKEditor
                            editor={ClassicEditor}
                            data={addData}
                            onChange={handleChange}
                          />
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Updatelayanan;
