import Nav from "./Nav";
import Home from "./Home";
import Layanan from "./Layanan";
import Tentang_kami from "./Tentang_Kami";
import React from "react";
import { BrowserRouter, Route, Switch, useRouteMatch } from "react-router-dom";
import Berita from "./Berita";
import page404 from "./404";
import Footer from "./Footer";
import DetailBerita from "./DetailBerita";
import DetailLayanan from "./DetailLayanan";
import Back from "../admin/Back";
import Login from "../admin/Login";

function Front() {
  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route exact path="/">
            <Nav />
            <Home />
            <Footer />
          </Route>
          <Route path="/home">
            <Nav />
            <Home />
            <Footer />
          </Route>

          <Route path="/layanan">
            <Nav />
            <Layanan />
            <Footer />
          </Route>

          <Route path="/tentangkami">
            <Nav />
            <Tentang_kami />
            <Footer />
          </Route>

          <Route path="/berita">
            <Nav />
            <Berita />
            <Footer />
          </Route>

          <Route path="/404">
            <Nav />
            <Tentang_kami />
            <Footer />
          </Route>

          <Route path="/detailberita/:beritaId">
            <Nav />
            <DetailBerita />
            <Footer />
          </Route>

          <Route path="/detaillayanan/:layananId">
            <Nav />
            <DetailLayanan />
            <Footer />
          </Route>

          <Route path="/admin" component={Back} />
          <Route path="/login" component={Login} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default Front;
