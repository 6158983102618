import Navside from "./Navside";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { link } from "../axios/link";
import { useParams } from "react-router";

function DetailLayanan(props) {
  const [isi, setIsi] = useState([]);
  const [Loading, setLoading] = useState(false);
  const { layananId } = useParams();

  useEffect(() => {
    async function fetchData() {
      const request = await link.get(`/layanan/${layananId}`);
      setIsi(request.data);
      setLoading(true);
    }
    fetchData();
    window.scrollTo(0, 0);
    Aos.init({ duration: 1500 });
  }, []);
  var date = formatDate(isi.updated_at);
  function formatDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }

  return (
    <div className="App">
      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-9">
            {/* loading spinner */}
            {Loading ? (
              isi.id.unvisible
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            <h1 className="teksbanner mb-4 mt-5">{isi.judullayanan}</h1>
            <hr className="mb-3" />
            <p>
              <small className="text-muted">
                {" "}
                <span className="fa fa-calendar-alt"></span> {date}
              </small>
            </p>
            <hr className="mb-3" />
            <div className="row px-3">
              <div className="card mb-3">
                <img src={isi.gambar} className="card-img-top" alt="..." />
                <div className="card-body">
                  <p className="card-text">{isi.isilayanan}</p>
                  <p className="card-text">
                    <small className="text-muted">Last updated at {date}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-3 pt-3">
            <Navside title="Berita Terkini" linkdata="limitberita" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailLayanan;
