import Navside from './Navside';
import Aos from 'aos';
import "aos/dist/aos.css";
import { useEffect } from 'react';

function Tentang_kami() {
  useEffect(() => {
    Aos.init({duration: 1500});
  }, []);
    return (
      <div className="App">
        <div className="container mt-5">
          <div className="row">
            {/* konten kiri */}
            <div className="col-sm-9">
              <h1 data-aos="fade-right" className="teksbanner mb-4 mt-5">Tentang Kami</h1>
              <hr data-aos="fade-right" className="mb-3"/>
              <b data-aos="fade-right" className="subtitle-tentangkami">Struktur Organisasi</b> <br/><br/>
              <img data-aos="fade-right" src="gambar/STRUKTUR.png" className="img-thumbnail" alt=""/> <br/><br/>
              <b  className="subtitle-tentangkami">Tentang Klinik Ummat </b> <br/>
              <p>
              Klinik UMMAT merupakan institusi layanan sosial Yayasan Solo Peduli Ummat dalam bidang kesehatan. Klinik UMMAT yang di peruntukkan dalam penanganan kesehatan umum dan rawat inap secara paripurna melalui pengelolaan dana masyarakat ataupun kelompok masyarakat
              </p>
              <p>Klinik UMMAT memberikan pelayanan gratis kepada masyarakat dhuafa yang telah terdaftar sebagai anggota (member) Klinik UMMAT</p>

              <b className="subtitle-tentangkami">Motto Klinik</b><br/>
              <p>Melayani Sepenuh Hati, Membantu Sepenuh Kemampuan.</p>

              <b className="subtitle-tentangkami">Visi Klinik</b><br/>
              <p>Menjadi Lembaga Kesehatan TERPERCAYA yang mengedepankan nilai-nilai SOSIAL dan RELIGI.</p>

              <b className="subtitle-tentangkami">Misi Klinik</b><br/>
              <ul>
                <li>Meningkatkan kapasitas SDM yang KOMPETEN, PROFESIONAL dan RELIGIUS.</li>
                <li>Mewujudkan manajemen yang HANDAL dan TERPERCAYA.</li>
                <li>Mewujudkan Sarana dan prasarana yang LENGKAP dan BERKUALITAS.</li>
                <li>Memberikan Pelayanan kesehatan yang PRIMA dan RELIGUS.</li>
                <li>Memberikan pelayanan GRATIS bagi masyarakat yang kurang mampu.</li>
              </ul>
              </div>
            {/* konten nav kanan */}
            <div className="col-sm-3 pt-3">
              <Navside title="Layanan Umat" linkdata="layanan"/>
            </div>
          </div>
          

        </div>
      </div>
    );
  }

  export default Tentang_kami;