import { useEffect, useState } from "react";
import { link } from "../../axios/link";
import { Redirect } from "react-router-dom";
function Detailmanajemenuser(props) {
  const [isi, setisi] = useState([]);
  const [Loading, setLoading] = useState(false);
  const userId = props.match.params.userId;

  function formatDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }
  async function fetchData() {
    const req = await link.get(`user/${userId}`);
    setisi(req.data);
    setLoading(true);
  }
  useEffect(() => {
    fetchData();
  }, []);
  if (sessionStorage.getItem("level") != "1") {
    sessionStorage.setItem("pesan", "Anda Harus Login Dahulu !");
    return <Redirect to="/admin" />;
  }
  return (
    <div className="App">
      <div className="main-wrapper">
        <div className="navbar-bg" />
        {/* Main Content */}
        <div className="main-content">
          {Loading ? (
            isi.id
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <section className="section">
            <div className="section-header">
              <h1>
                <i
                  className="fa fa-user me-4"
                  style={{ fontSize: "0.9em" }}
                ></i>
                Informasi User
              </h1>
            </div>
            <div className="section-body">
              <h2 className="section-title">
                Ini detail Informasi dari : {isi.username}
              </h2>
              <p className="section-lead">Detail Informasi Pengguna</p>
              <div className="row mt-sm-4">
                <div className="col-12 col-md-12 col-lg-8">
                  <div className="card profile-widget">
                    <div className="profile-widget-header">
                      <img
                        alt="image"
                        src={`${isi.image}`}
                        className="rounded-circle profile-widget-picture"
                      />
                      <div className="profile-widget-items">
                        <div className="profile-widget-item">
                          <div className="profile-widget-item-label">
                            Level Pengguna
                          </div>
                          <div className="profile-widget-item-value">
                            {isi.level == "1"
                              ? "Administrator Utama"
                              : "Administrator Penyunting"}
                          </div>
                        </div>
                        <div className="profile-widget-item">
                          <div className="profile-widget-item-label">
                            Akun Status
                          </div>
                          <div className="profile-widget-item-value">
                            {isi.status == "1" ? "Aktif" : "Non-Aktif"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile-widget-description">
                      <div className="profile-widget-name">
                        {isi.name}
                        <div className="text-muted d-inline font-weight-normal">
                          <div className="slash" /> {isi.username}
                        </div>
                      </div>
                      <table className="table table-striped">
                        <tr>
                          <td>Nama Lengkap</td>
                          <td>: {isi.name}</td>
                        </tr>
                        <tr>
                          <td>Username</td>
                          <td>: {isi.username}</td>
                        </tr>
                        <tr>
                          <td>Email Pengguna</td>
                          <td>: {isi.email}</td>
                        </tr>
                        <tr>
                          <td>Level Pengguna</td>
                          <td>
                            :
                            {isi.level == "1"
                              ? "Administrator Utama"
                              : "Administrator Penyunting"}
                          </td>
                        </tr>
                        <tr>
                          <td>Level</td>
                          <td>: {isi.status == "1" ? "Aktif" : "Non-Aktif"}</td>
                        </tr>
                        <tr>
                          <td>Akun Dibuat</td>
                          <td>: {formatDate(isi.created_at)}</td>
                        </tr>
                      </table>
                    </div>
                    <div className="card-footer">
                      <span className="text-muted form-text">
                        Non-aktif dan Reset Password pengguna ini dapat
                        dilakukan di edit data pengguna !
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
export default Detailmanajemenuser;
