import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import Sidenav from "./Sidenav";
import Topnav from "./Topnav";
import FooterAdmin from "./FooterAdmin";
import BeritaAdmin from "./berita/BeritaAdmin";
import Tambahberita from "./berita/Tambahberita";
import DetailBeritaAdmin from "./berita/DetailBeritaAdmin";
import { Redirect } from "react-router-dom";
import Updateberita from "./berita/Updateberita";
import LayananAdmin from "./layanan/LayananAdmin";
import TambahLayanan from "./layanan/TambahLayanan";
import DetailLayananAdmin from "./layanan/DetailLayananAdmin";
import Updatelayanan from "./layanan/UpdateLayanan";
import InformasiUser from "./infromasi user/InformasiUser";
import ManajemenUser from "./manajemen user/ManajemenUser";
import Detailmanajemenuser from "./manajemen user/Detailmanajemenuser";
import Editmanajemenuser from "./manajemen user/Editmanajemenuser";
function Back() {
  if (
    sessionStorage.getItem("token") == "undefined" ||
    sessionStorage.getItem("token") == null
  ) {
    sessionStorage.setItem("pesan", "Anda Harus Login Dahulu !");
    return <Redirect to="/login" />;
  }
  return (
    <BrowserRouter>
      <div className="App">
        <Topnav />
        <Sidenav />
        <Switch>
          <Route path="/admin" component={Dashboard} exact />

          {/* Berita */}
          <Route path="/admin/berita" component={BeritaAdmin} />
          <Route path="/admin/tambahberita" component={Tambahberita} />
          <Route
            path="/admin/detailberita/:beritaId"
            component={DetailBeritaAdmin}
          />
          <Route path="/admin/editberita/:beritaId" component={Updateberita} />

          {/* Layanan */}
          <Route path="/admin/layanan" component={LayananAdmin} />
          <Route path="/admin/tambahlayanan" component={TambahLayanan} />
          <Route
            path="/admin/detaillayanan/:layananId"
            component={DetailLayananAdmin}
          />
          <Route
            path="/admin/editlayanan/:layananId"
            component={Updatelayanan}
          />

          {/* Informasi User */}
          <Route path="/admin/informasiuser" component={InformasiUser} />

          {/* Manajemen User */}
          <Route path="/admin/manajemenuser" component={ManajemenUser} />
          <Route
            path="/admin/detailuser/:userId"
            component={Detailmanajemenuser}
          />
          <Route path="/admin/edituser/:userId" component={Editmanajemenuser} />
        </Switch>
        <FooterAdmin />
      </div>
    </BrowserRouter>
  );
}

export default Back;
