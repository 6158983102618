import Aos from "aos";
import Navside from "./Navside";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { link } from "../axios/link";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router";

function DetailBerita(props) {
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const { beritaId } = useParams();

  useEffect(() => {
    async function fetchData() {
      const request = await link.get(`/berita/${beritaId}`);
      setData(request.data);
      setLoading(true);
    }

    function componentDidMount() {
      window.scrollTo(0, 0);
    }
    componentDidMount();
    Aos.init({ duration: 1500 });
    fetchData();
  }, []);

  var date = formatDate(data.tanggal);
  function formatDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }

  return (
    <div className="App">
      <div className="container mt-5">
        <div className="row">
          {Loading ? (
            data.id.unvisible
          ) : (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <div className="col-sm-9">
            <h1 className="teksbanner mb-4 mt-5">{data.judulberita}</h1>
            <hr className="mb-3" />
            <p>
              <small className="text-muted">
                {" "}
                <span className="fa fa-calendar-alt"></span> {date}
              </small>
            </p>
            <hr className="mb-3" />
            <div className="row px-3">
              <div className="card mb-3">
                <img src={data.gambar} className="card-img-top" alt="..." />
                <div className="card-body">
                  <p className="card-text">{ReactHtmlParser(data.isiberita)}</p>
                  <p className="card-text">
                    <small className="text-muted">Last updated at {date}</small>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-3 pt-3">
            <Navside title="Layanan Umat" linkdata="layanan" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailBerita;
