import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { link } from "../axios/link";
import { useEffect, useState } from "react";

function Navside(props) {
  const [isi, setIsi] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [kondisi, setKondisi] = useState(false);
  const linknya = props.linkdata;

  useEffect(() => {
    async function fetchData() {
      try {
        const request = await link.get(`/${linknya}`).then((res) => {
          setIsi(res.data);
        });
        setLoading(true);
      } catch (e) {
        console.log(e);
      }

      if (linknya === "layanan") {
        setKondisi(true);
      } else {
        setKondisi(false);
      }
    }
    fetchData();
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <div>
      <div data-aos="fade-down" className="fluid-container px-3 mb-5">
        <div className="row mt-5">
          <div className="col-12 bg-primarygreen-main p-3 color-white ">
            <span className="fa fa-bookmark"></span> {props.title}
          </div>
        </div>
        {isi.map((val, index) => (
          <div className="row " style={{ overflowY: "hidden" }} key={index}>
            <div className="col-12 bg-fafa ">
              <ul className="list-group list-group-flush ">
                <li
                  className="list-group-item bg-fafa px-0 "
                  style={{ background: "#FAFAFA" }}
                >
                  {kondisi ? (
                    <Link to={`/detaillayanan/${val.id}`}>
                      <span className="linkteks">{val.judullayanan}</span>
                    </Link>
                  ) : (
                    <Link to={`/detailberita/${val.id}`}>
                      <span className="linkteks">{val.judulberita}</span>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Navside;
