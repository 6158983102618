import React, { Component } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Front from "./pages/Front";
import Back from "./admin/Back";
import Login from "./admin/Login";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Front} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
