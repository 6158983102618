import React from "react";
import { useState, useEffect } from "react";
import { link } from "../axios/link";
import { useForm } from "react-hook-form";
import { useHistory, Redirect } from "react-router-dom";
function Login() {
  const { register, handleSubmit, reset } = useForm();
  const history = useHistory();
  if (
    sessionStorage.getItem("token") != "undefined" &&
    sessionStorage.getItem("token") != null
  ) {
    return <Redirect to="/admin" />;
  }
  console.log(sessionStorage.getItem("token"));
  async function login(data) {
    const res = await link.post("/login", data);
    let token = await res.data.token;
    let pesan = await res.data.pesan;
    sessionStorage.setItem("pesan", pesan);
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("level", res.data.data.level);
    sessionStorage.setItem("user", res.data.data.name);
    sessionStorage.setItem("foto", res.data.data.image);
    sessionStorage.setItem("id_user", res.data.data.id);
    reset();
    console.log(sessionStorage.getItem("id_user"));
    if (sessionStorage.getItem("token") != "undefined") {
      history.go("/admin");
    }
  }
  const pesan = sessionStorage.getItem("pesan");
  return (
    <div className="App">
      <section className="section">
        <div className="container mt-5">
          {pesan == null ? (
            pesan
          ) : (
            <div className="alert alert-warning">
              <div className="alert-body">{pesan}</div>
            </div>
          )}

          <div className="row">
            <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
              <div className="login-brand">
                <img
                  src="gambar/logoonly-04.png"
                  alt="logo"
                  width={60}
                  className="rounded-circle"
                />
              </div>
              <div className="card card-primary">
                <div className="card-header">
                  <h4>Login for Administrator access</h4>
                </div>
                <div className="card-body">
                  <form
                    onSubmit={handleSubmit(login)}
                    className="needs-validation"
                    noValidate
                  >
                    <div className="form-group">
                      <label htmlFor="username">Username</label>
                      <input
                        id="username"
                        type="text"
                        className="form-control"
                        name="username"
                        tabIndex={1}
                        required
                        autoFocus
                        {...register("username", { required: true })}
                      />
                      <div className="invalid-feedback">
                        Please fill in your email
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="d-block">
                        <label htmlFor="password" className="control-label">
                          Password
                        </label>
                      </div>
                      <input
                        id="password"
                        type="password"
                        className="form-control"
                        name="password"
                        tabIndex={2}
                        required
                        {...register("password", { required: true })}
                      />
                      <div className="invalid-feedback">
                        please fill in your password
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          name="remember"
                          className="custom-control-input"
                          tabIndex={3}
                          id="remember-me"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="remember-me"
                        >
                          Remember Me
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block"
                        tabIndex={4}
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="simple-footer">Copyright © Klinikummt 2021</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
