import React from "react";
import { useEffect, useState } from "react";
import { link } from "../axios/link";

function Dashboard() {
  const [isi, setIsi] = useState([]);

  async function fetchData() {
    try {
      const req = await link.get("/dashboardadmin");
      setIsi(req.data);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="App">
      <div className="main-wrapper">
        <div className="navbar-bg" />
        {/* Main Content */}
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>
                <i
                  className="fa fa-fire me-4"
                  style={{ fontSize: "0.8em" }}
                ></i>
                Dashboard
              </h1>
            </div>
            <div className="section-body">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="card card-statistic-1">
                    <div className="card-icon bg-primary">
                      <i className="fas fa-users" />
                    </div>
                    <div className="card-wrap">
                      <div className="card-header">
                        <h4>Admin</h4>
                      </div>
                      <div className="card-body">{isi.countuser}</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="card card-statistic-1">
                    <div className="card-icon bg-danger">
                      <i className="far fa-newspaper" />
                    </div>
                    <div className="card-wrap">
                      <div className="card-header">
                        <h4>Berita</h4>
                      </div>
                      <div className="card-body">{isi.countberita}</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="card card-statistic-1">
                    <div className="card-icon bg-warning">
                      <i className="fas fa-hand-holding-medical" />
                    </div>
                    <div className="card-wrap">
                      <div className="card-header">
                        <h4>Layanan</h4>
                      </div>
                      <div className="card-body">{isi.countlayanan}</div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="card card-statistic-1">
                    <div className="card-icon bg-success">
                      <i className="fas fa-id-card" />
                    </div>
                    <div className="card-wrap">
                      <div className="card-header">
                        <h4>Member</h4>
                      </div>
                      <div className="card-body">-</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
