import Aos from "aos";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { link } from "../axios/link";

function Home() {
  const [databerita, setDataberita] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const request = await link.get("/limitberita");
      setDataberita(request.data);
    }
    fetchData();
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className="App">
      {/* banner */}
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <div className="boxbanner">
              <h1 data-aos="fade-right">Klinik Ummat</h1>
              <h1 data-aos="fade-right" data-aos-delay="150">
                Program Solusi
              </h1>
              <h1 data-aos="fade-right" data-aos-delay="300">
                Pelayanan Kesehatan
              </h1>
              <h6 data-aos="fade-right" data-aos-delay="550">
                Cek Kesehatan dengan berbagai layanan untuk umat dengan
                pelayanan yang terpadu.
              </h6>
              <div className="row pt-4 spacetotop">
                <div
                  data-aos="fade-right"
                  data-aos-delay="1050"
                  className="col-md-4"
                  style={{ marginBottom: "3%" }}
                >
                  <div className="btn btn-primary btn-greenprimary w-100 py-3">
                    {" "}
                    Cek Layanan{" "}
                  </div>
                </div>
                <div className="col-md-4">
                  <div
                    data-aos="fade-right"
                    data-aos-delay="1050"
                    className="btn btn-outline-primary btn-greensecond-outline w-100 py-3"
                  >
                    {" "}
                    Cek Berita Terkini
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <img
              data-aos="fade-left"
              data-aos-delay=""
              src="gambar/BANNERs.png"
              className="imgbaner"
              width="100%"
              alt="perempuan melihat hasil cek laboratorium "
            />
          </div>
        </div>
      </div>

      {/* Layanan */}
      <div className="container-fluid bg-primarygreen overflow-hidden">
        <img
          data-aos="fade-up"
          src="gambar/back.gif"
          width="404px"
          className="bg-logo position-absolute top-100"
          alt="logo klinikummat"
        />
        <div className="contianer">
          {/* judul section : pelayanan*/}
          <div className="row text-center">
            <div className="col-12">
              <img
                data-aos="fade-down"
                src="gambar/logowhite.gif"
                width="103px"
                alt=""
              />
            </div>
            <div className="col-12">
              <h1
                data-aos="fade-down"
                data-aos-delay="50"
                className="color-white judul-section"
              >
                Pelayanan Kesehatan
              </h1>
            </div>
            <div className="col-12">
              <h6
                data-aos="fade-down"
                data-aos-delay="100"
                className="subjudul-section"
              >
                Inilah pelayanan kesehatan yang populer di klinik ummat
              </h6>
            </div>
          </div>

          {/* konten section : pelayanan */}
          <div className="container">
            <div className="row mt-5 align-content-center">
              {/* Pelayanan : periksa umum */}
              <div
                data-aos="fade-down"
                data-aos-delay="100"
                className="col-md-4 position-relative"
              >
                <div className="box-img-source1 box-img"></div>
                <div className="card width-potong mx-auto">
                  <div className="card-body">
                    <h5 className="card-title fw-bolder color-primary-green my-4">
                      Periksa Umum
                    </h5>
                    <p className="card-text my-5">
                      Ayo cek kesehehatan di pelayanan periksa umum pada klinik
                      umat,
                    </p>
                    <hr />
                    <p className="card-text">
                      <small className="text-muted">
                        Fasilitas : <br /> Dokter umum, Periksa Setiap hari,
                      </small>
                    </p>

                    <Link
                      to="/layanan"
                      className="btn btn-warning mt-4 px-5 py-2"
                    >
                      Cek Fasilitas
                    </Link>
                  </div>
                </div>
              </div>
              {/* Pelayanan : persalinan */}
              <div
                data-aos="fade-down"
                data-aos-delay="300"
                className="col-md-4 position-relative"
              >
                <div className="box-img-source2 box-img"></div>
                <div className="card width-potong mx-auto">
                  <div className="card-body">
                    <h5 className="card-title fw-bolder color-primary-green my-4">
                      Persalinan
                    </h5>
                    <p className="card-text my-5">
                      Ayo cek pelayanan persalinan kami di pelayanan persalinan
                      pada klinik umat,
                    </p>
                    <hr />
                    <p className="card-text">
                      <small className="text-muted">
                        Fasilitas : <br /> Dokter umum, Periksa Setiap hari,
                      </small>
                    </p>

                    <Link
                      to="/layanan"
                      className="btn btn-warning mt-4 px-5 py-2"
                    >
                      Cek Fasilitas
                    </Link>
                  </div>
                </div>
              </div>
              {/* Pelayanan : rawat inap */}
              <div
                data-aos="fade-down"
                data-aos-delay="600"
                className="col-md-4 position-relative"
              >
                <div className="box-img-source3 box-img"></div>
                <div className="card width-potong mx-auto">
                  <div className="card-body">
                    <h5 className="card-title fw-bolder color-primary-green my-4">
                      Rawat Inap
                    </h5>
                    <p className="card-text my-5">
                      Klinik ummat juga ada pelayanan rawat inap, itu salah satu
                      pelayanan kami
                    </p>
                    <hr />
                    <p className="card-text">
                      <small className="text-muted">
                        Fasilitas : <br /> Dokter umum, Periksa Setiap hari,
                      </small>
                    </p>

                    <Link
                      to="/layanan"
                      className="btn btn-warning mt-4 px-5 py-2"
                    >
                      Cek Fasilitas
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* button selengkapnya */}
          <center>
            <button
              data-aos="fade-down"
              className="btn btn-warning btn-yellowprimary mb-5 px-5 py-3"
              href="#"
            >
              <i className="fas fa-long-arrow-alt-right"></i> Lihat Selengkapnya
            </button>
          </center>
        </div>
      </div>
      {/* Tentang Kami */}
      <div className="container mt-5">
        <div className="container px-5" style={{ width: "80%" }}>
          <h1 data-aos="fade-right" className="judul-section color-semigray">
            {" "}
            Tentang Kami
          </h1>
          <iframe
            data-aos="zoom-in"
            data-aos-delay="100"
            className=" mt-3"
            width="100%"
            height="407px"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            src="https://drive.google.com/file/d/1ENIKsDBcJ0nXdynHAXcsvtyR2JqdlhUN/preview"
            allowFullScreen
          ></iframe>
          <p
            data-aos="zoom-in"
            className="text-body mt-3 text-center"
            style={{ letterSpacing: "0.07em" }}
          >
            Klinik UMMAT merupakan institusi layanan sosial Yayasan Solo Peduli
            Ummat dalam bidang kesehatan. Klinik UMMAT yang di peruntukkan dalam
            penanganan kesehatan umum dan rawat inap secara paripurna melalui
            pengelolaan dana masyarakat ataupun kelompok masyarakat.{" "}
          </p>
        </div>
        <br />
        <br />
        <center>
          <button
            data-aos="fade-down"
            className="btn btn-warning btn-yellowprimary mb-5 px-5 py-3"
            href="#"
          >
            <i className="fas fa-long-arrow-alt-right"></i> Cek Detail
          </button>
        </center>
      </div>
      <svg
        data-aos="fade-left"
        className="abstrak-glow-green"
        width="163"
        height="207"
        viewBox="0 0 163 207"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.58326 25.8055C34.5643 -20.9308 124.02 6.33207 165 25.8055V207C99.0357 166.075 -25.3978 72.5418 4.58326 25.8055Z"
          fill="#E8B81F"
          fillOpacity="0.51"
        />
      </svg>
      <svg
        data-aos="fade-left"
        className="abstrak-glow-green"
        width="262"
        height="220"
        viewBox="0 0 262 220"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M184.021 30.8415C189.684 -9.11568 239.7 -1.86576 264 6.75385V220H0C9.31904 218.347 35.2472 212.632 64.4075 202.997C100.858 190.953 88.4719 184.932 99.4424 147.383C110.413 109.835 112.536 128.963 145.448 104.876C178.359 80.7881 176.944 80.788 184.021 30.8415Z"
          fill="#39B54A"
          fillOpacity="0.58"
        />
      </svg>

      {/* Berita Terbaru */}
      <svg
        className="position-absolute mt-5"
        width="33"
        height="153"
        viewBox="0 0 33 153"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.5 53C30.5 5.00004 10 -1.99996 0 0.500039V153C11.6667 139.667 34.5 101 32.5 53Z"
          fill="#8CD496"
        />
      </svg>

      <div className="container mt-5 pt-5">
        <div className="row">
          <div className="col-sm-6 text-center">
            <div className="col-12">
              <img
                data-aos="fade-down"
                data-aos-delay=""
                src="gambar/back.gif"
                width="62px"
                alt=""
              />
            </div>
            <div className="col-12">
              <h1
                data-aos="fade-down"
                data-aos-delay="300"
                className="color-primary-green-main judul-section "
              >
                Berita Terkini
              </h1>
            </div>
            <div className="col-12">
              <h6
                data-aos="fade-down"
                data-aos-delay="400"
                className="letter-spacing px-5 py-2"
              >
                Di Klinikummat kami tidak hanya melakukan pelayanan kesehatan
                pada klinik saja akan tetapi kami juga aktif melakukan pelayanan
                kepada masyarakat pada bidang kesehatan. Mulai dari pengabdian,
                pelayanan kesehatan, progra program kesehatan yang membantu
                banyak kalangan masnyarakat
                <br />
                <br />
                Kami akan selalu update program program kami pada halaman
                berita, kami akan berusaha sebaik mungkin dalam penyampaian
                informasi pada masyarakat.
              </h6>
            </div>
          </div>
          <div className="col-sm-6">
            {databerita.map((value, index) => (
              <div
                key={index}
                data-aos="fade-down"
                className="alert alert-primary bg-primarygreenopacity "
                role="alert"
              >
                <Link to={`/detailberita/${value.id}`}>
                  <span className="text-none">{value.judulberita}</span>
                  <span className="badge bg-warning ">News</span>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <svg
        className="abstrak-glow-yellow"
        style={{ zIndex: "-1" }}
        width="45"
        height="195"
        viewBox="0 0 45 195"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.43105 106.5C-9.56895 62.5 25.7644 17.1667 44.931 0V195C35.7644 183.833 14.431 150.5 2.43105 106.5Z"
          fill="#F3DB8D"
        />
      </svg>
      <br />
      <br />
      <div className="row mt-5 pt-5" style={{ width: "101%" }}>
        <div data-aos="zoom-in" className="col-sm-6 p-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3954.8926005453995!2d110.78859635581959!3d-7.586667835536168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a15c16a7d925f%3A0x25e36e35e07d57f9!2sKlinik%20Ummat%20SoloPeduli!5e0!3m2!1sen!2sid!4v1618130124240!5m2!1sen!2sid"
            width="100%"
            height="500"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="col-sm-6 bg-primarygreen-main px-5 py-5">
          <h1
            data-aos="fade-right"
            className="color-white judul-contactkami mt-5"
          >
            Hubungi Kami
          </h1>
          <p data-aos="fade-down" className="content-contactkami mt-5">
            Jl. Songgo Bumi No.11-9, Dusun I, Manang, Kab. Sukoharjo
          </p>
          <span data-aos="fade-down" className="content-contactkami">
            <i className="fas fa-envelope fontsmall"></i>{" "}
            klinikummat@klinikummat.com
          </span>{" "}
          <br />
          <span
            data-aos="fade-down"
            data-aos-delay="100"
            className="content-contactkami"
          >
            <i className="fas fa-phone fontsmall"></i> +(0271)746 2097
          </span>{" "}
          <br />
          <span
            data-aos="fade-down"
            data-aos-delay="200"
            className="content-contactkami"
          >
            <i className="fa fa-whatsapp"></i> +62 813-3210-6647
          </span>{" "}
          <br />
          <span
            data-aos="fade-down"
            data-aos-delay="300"
            className="content-contactkami"
          >
            <i className="fa fa-facebook"></i> &nbsp; Klinik Ummt
          </span>{" "}
          <br />
          <span
            data-aos="fade-down"
            data-aos-delay="350"
            className="content-contactkami"
          >
            <i className="fa fa-instagram"></i> &nbsp;klinikummat
          </span>{" "}
          <br /> <br />
          <hr className="color-white" />
          <span
            data-aos="fade-down"
            data-aos-delay=""
            className="content-contactkami"
          >
            Cek pintasan !
          </span>{" "}
          <br />
          <a href="">
            <span
              data-aos="fade-right"
              data-aos-delay=""
              className="boxicon bg-facebook"
            >
              <i className="fa fa-facebook fontsize"></i>
            </span>
          </a>
          <a href="">
            <span
              data-aos="fade-right"
              data-aos-delay="100"
              className="boxicon bg-ig"
            >
              <i className="fa fa-instagram fontsize"></i>
            </span>
          </a>
          <a href="">
            <span
              data-aos="fade-right"
              data-aos-delay="200"
              className="boxicon bg-whatsapp"
            >
              <i className="fa fa-whatsapp fontsize"></i>
            </span>
          </a>
          <a href="">
            <span
              data-aos="fade-right"
              data-aos-delay="300"
              className="boxicon bg-email"
            >
              <i className="fa fa-envelope fontsize"></i>
            </span>
          </a>
        </div>
      </div>

      <div className="container text-center align-content-center mt-5 mb-5">
        <h1 data-aos="fade-down" className="judul-contactkami mt-5">
          Support By
        </h1>
        <img
          data-aos="fade-down"
          data-aos-delay="200"
          src="gambar/LOGOSOLPED.png"
          alt="logo solopeduli"
          width="138px"
          className="mt-5 mx-5"
        />
        <img
          data-aos="fade-down"
          data-aos-delay="200"
          src="gambar/logo.png"
          alt="logo solopeduli"
          width="228px"
          className="mt-5 mx-5"
        />
      </div>
    </div>
  );
}

export default Home;
