function FooterAdmin(){
    return (
        <div className="App">
            <footer className="main-footer">
            <div className="footer-left">
                Copyright © 2021 <div className="bullet" /> Created By <a href="https://www.instagram.com/adansubtyatama/">Adan Subtyatama</a>
            </div>
            <div className="footer-right">
                1.0
            </div>
            </footer>
        </div>
    )
}
export default FooterAdmin;