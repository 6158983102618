import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
function Topnav() {
  const history = useHistory();

  function hapus() {
    sessionStorage.clear();
    history.push("/");
    sessionStorage.setItem("pesan", "Logut berhasil, Assalamualaikum !");
    window.location.reload();
  }
  return (
    <div className="App">
      <nav className="navbar navbar-expand-lg main-navbar">
        <ul className="navbar-nav mr-3 mr-auto">
          <li>
            <a href="#" data-toggle="sidebar" className="nav-link nav-link-lg">
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>

        <ul className="navbar-nav navbar-right">
          <li className="dropdown">
            <a
              href="#"
              data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"
              id="dropdownMenuButton"
            >
              <img
                alt="image"
                src={sessionStorage.getItem("foto")}
                className="rounded-circle mr-1"
              />
              <div className="d-sm-none d-lg-inline-block">
                Hi, {sessionStorage.getItem("user")}
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton"
            >
              {/* <div className="dropdown-title">Logged in 5 min ago</div> */}
              <NavLink to="/userinfo" className="dropdown-item has-icon">
                <i className="far fa-user" /> Profile
              </NavLink>
              <div className="dropdown-divider" />
              <a
                style={{ cursor: "pointer" }}
                onClick={hapus}
                className="dropdown-item has-icon text-danger"
              >
                <i className="fas fa-sign-out-alt" /> Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Topnav;
