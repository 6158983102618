import { useState, useEffect } from "react";
import { link } from "../../axios/link";
import ReactHtmlParser from "react-html-parser";
function DetailLayananAdmin(props) {
  const [isi, setIsi] = useState([]);
  const [Loading, setLoading] = useState(false);
  const layananId = props.match.params.layananId;

  useEffect(() => {
    async function fetchData() {
      const request = await link.get(`/layanan/${layananId}`);
      setIsi(request.data);
      setLoading(true);
    }
    fetchData();
  }, []);

  function formatDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }
  return (
    <div className="App">
      <div className="main-wrapper">
        <div className="navbar-bg" />
        {/* Main Content */}
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>
                <i
                  className="fa fa-file me-4"
                  style={{ fontSize: "0.9em" }}
                ></i>
                Detail Layanan
              </h1>
            </div>
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Detail Layanan</h4>
                    </div>
                    <div className="card-body">
                      {Loading ? (
                        isi.id.invisible
                      ) : (
                        <div className="d-flex justify-content-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                      <article className="article article-style-c">
                        <div
                          className="article-header"
                          style={{ height: "350px" }}
                        >
                          <div
                            className="article-image"
                            style={{
                              backgroundImage: `url('${isi.gambar}')`,
                            }}
                          ></div>
                        </div>
                        <div className="article-details">
                          <div className="article-category">
                            <a>News</a> <div className="bullet" />{" "}
                            <a>{formatDate(isi.updated_at)}</a>
                          </div>
                          <div className="article-title">
                            <h4 className="color-primary-green-main">
                              {isi.judullayanan}
                            </h4>
                          </div>
                          <p>{ReactHtmlParser(isi.isilayanan)}</p>
                          <div className="article-user">
                            <div className="article-user-details">
                              <div className="user-detail-name">
                                <span className="color-primary-green-main">
                                  {isi.name}
                                </span>
                              </div>
                              <div className="text-job">Penyunting</div>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default DetailLayananAdmin;
