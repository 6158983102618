import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm } from "react-hook-form";
import { link } from "../../axios/link";
import { useHistory } from "react-router";

function Updateberita(props) {
  const [addData, setVal] = useState();
  const [isi, setIsi] = useState([]);
  const [Loading, setLoading] = useState();
  const { register, handleSubmit, reset, errors, setValue } = useForm();
  const history = useHistory(false);
  async function updateBerita(data) {
    console.log(data.gambar[0]);
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append("judulberita", data.judulberita);
    formData.append("tanggal", data.tanggal);
    formData.append("gambar", data.gambar[0]);
    formData.append("isiberita", addData);
    formData.append("user_id", sessionStorage.getItem("id_user"));
    link
      .post(`/berita/${props.match.params.beritaId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => alert(res.data.pesan));
    setVal("");
    reset();
    history.push("/admin/berita");
  }

  useEffect(() => {
    async function fetchData() {
      const req = await link.get(`/berita/${props.match.params.beritaId}`);
      setIsi(req.data);
      setVal(req.data.isiberita);
      setValue("judulberita", req.data.judulberita);
      setValue("tanggal", req.data.tanggal);
      setLoading(true);
    }
    fetchData();
  }, []);
  const handleChange = (e, editor) => {
    const datas = editor.getData();
    setVal(datas);
  };

  return (
    <div>
      <div className="main-wrapper">
        <div className="navbar-bg" />
        {/* Main Content */}
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>
                <i
                  className="fa fa-plus-square me-4"
                  style={{ fontSize: "0.9em" }}
                ></i>
                Update Berita
              </h1>
            </div>
            <div className="section-body">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h4>Update Data Berita !</h4>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleSubmit(updateBerita)}>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>Judul Berita</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("judulberita", { required: true })}
                              placeholder="Masukan judul berita..."
                              name="judulberita"
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>Tanggal Berita</label>
                            <input
                              type="date"
                              className="form-control"
                              {...register("tanggal", { required: true })}
                              placeholder="Masukan tanggal berita..."
                              name="tanggal"
                              required
                            />
                          </div>
                        </div>
                        <div>
                          <div className="form-group mb-0">
                            <label>Gambar</label>
                          </div>
                          <img
                            src={`${isi.gambar}`}
                            width="60%"
                            alt="gambar"
                            className="img-thumbnail"
                          />
                        </div>
                        <div className="mb-3 mt-2">
                          <div className="form-group">
                            <input
                              type="file"
                              className="form-control"
                              {...register("gambar", { required: false })}
                              placeholder="Masukan gambar..."
                              name="gambar"
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="form-group">
                            <label>Isi Berita</label>
                          </div>
                          <CKEditor
                            editor={ClassicEditor}
                            data={addData}
                            onChange={handleChange}
                          />
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Updateberita;
