import { Link } from "react-router-dom";
import Navside from "./Navside";
import Aos from "aos";
import "aos/dist/aos.css";
import { link } from "../axios/link";
import ReactHtmlParser from "react-html-parser";
import { useEffect, useState } from "react";

function Berita() {
  const [isi, setIsi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setIsiPerPage] = useState(5);
  useEffect(() => {
    async function fetchData() {
      const request = await link.get("/berita");
      // console.log(request.data);
      setIsi(request.data);
      setLoading(true);
    }

    Aos.init({ duration: 1500 });
    fetchData();
  }, []);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentPosts = isi.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumbers) => setCurrentPage(pageNumbers);
  const pageNumbers = [];
  for (let index = 1; index <= Math.ceil(isi.length / postPerPage); index++) {
    pageNumbers.push(index);
  }
  var date = formatDate(isi.tanggal);
  function formatDate(string) {
    var options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(string).toLocaleDateString([], options);
  }
  return (
    <div className="App">
      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-9">
            <h1 data-aos="fade-right" className="teksbanner mb-4 mt-5">
              Berita Terkini
            </h1>
            <hr className="mb-3" />

            {/* spinner */}
            {loading ? (
              isi.id
            ) : (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}

            <div data-aos="fade-down" className="row">
              {currentPosts.map((val, index) => (
                <div className="row px-3" key={index}>
                  <div className="card mb-3 border-0">
                    <div className="row bg-fafa">
                      <div className="col-md-4 g-0">
                        <img
                          src={val.gambar}
                          className="mt-4 thumbnail"
                          width="290px"
                          height="auto"
                          alt="gambar"
                          style={{ maxWidth: "540px", height: "auto" }}
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="card-body">
                          <h5 className="card-title color-primary-green-main">
                            {val.judulberita}
                          </h5>
                          <p className="card-text">
                            <small className="text-muted">
                              <span className="fa fa-calendar-alt"></span>{" "}
                              {formatDate(val.tanggal)}
                            </small>
                          </p>
                          <p className="card-text text-justify">
                            {ReactHtmlParser(val.isiberita.substring(0, 200))}
                            ...
                          </p>
                          <Link to={`/detailberita/${val.id}`}>
                            <span className="btn btn-warning btn-yellowprimary">
                              BACA SELENGKAPNYA
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <nav className="mt-5" aria-label="Page navigation">
              <ul className="pagination">
                {pageNumbers.map((number) => (
                  <li key={number} className="page-item">
                    <a
                      onClick={() => paginate(number)}
                      className="page-link"
                      href="#"
                    >
                      {number}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className="col-sm-3 pt-3">
            <Navside title="Layanan Umat" linkdata="layanan" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Berita;
